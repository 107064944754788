<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="6" v-if="showOrigin">
        <v-autocomplete
          :ref="locationRef"
          :items="displayedLocations"
          :multiple="autocompleteProps.multiple"
          :return-object="autocompleteProps.returnObject"
          :no-data-text="$t('common.fields.noDataAvailable')"
          v-bind="autocompleteProps"
          v-model="location"
          color="primary"
          item-color="primary"
          item-text="name"
          item-value="locationId"
          @update:search-input="handleLocationsSearchChange($event)"
          @change="fetchStorages(null, location)"
          @click:clear="clearLocation()"
          :loading="loadings.origin"
          :rules="requireRules"
        >
          <template v-slot:label>
            {{ locationLabel }}
            <strong v-if="autocompleteProps.required" class="red--text">*</strong>
          </template>
          <template v-slot:item="{ item, attrs }">
            <div style="max-width: 180px">
              <read-more
                :classes="'mb-0'"
                :text="item.name"
                :max-chars="40"
                :font-size="14"
              />
            </div>
          </template>
          <template v-slot:append-item>
            <AFooterPaginatorField
              :items-length="filteredLocations.length"
              :items-per-page="pagination.locations.itemsPerPage"
              :itemPerPageText="false"
              :current-page="pagination.locations.currentPage"
              :valid="true"
              @update:current-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
              @update:items-per-page="(e) => updatePagination('locations', { page: e.page, itemsPerPage: e.itemsPerPage })"
            />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="showDestination">
        <v-autocomplete
          v-if="showDestination"
          :multiple="autocompleteProps.multiple"
          :return-object="autocompleteProps.returnObject"
          :ref="storageRef"
          :items="displayedStorages"
          :no-data-text="$t('common.fields.noDataAvailable')"
          v-bind="autocompleteProps"
          v-model="storageDevice"
          item-text="control_label"
          item-value="storageDeviceId"
          @update:search-input="handleStoragesSearchChange($event)"
          @change="$emit('update:storage', storageDevice)"
          @click:clear="clearStorage()"
          :disabled="!location"
          :loading="loadings.destination"
          :rules="requireRules"
          color="primary"
          item-color="primary"
        >
          <template v-slot:label>
            {{ storageLabel }}
            <strong v-if="autocompleteProps.required" class="red--text">*</strong>
          </template>
          <template v-slot:item="{ item, attrs }">
            <div style="max-width: 180px">
              <read-more
                :classes="'mb-0'"
                :text="item.control_label"
                :max-chars="40"
                :font-size="14"
              />
            </div>
          </template>
          <template v-slot:append-item>
            <AFooterPaginatorField
              :items-length="filteredStorages.length"
              :items-per-page="pagination.storages.itemsPerPage"
              :itemPerPageText="false"
              :current-page="pagination.storages.currentPage"
              :valid="true"
              @update:current-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
              @update:items-per-page="(e) => updatePagination('storages', { page: e.page, itemsPerPage: e.itemsPerPage })"
            />
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue"
import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";
import {SimpleLocationMixin} from "@/components/filters/mixins/simple-location.mixin";

export default {
  components: { AFooterPaginatorField, ReadMore },
  mixins: [SimpleLocationMixin],

  async mounted() {
    await this.fetchLocations()

    this.$set(this, 'locationData', this.sLocations);
    this.$set(this, 'storageData', this.sStorageDevices);
  },

  destroyed() {
    console.log('destroyed locations filters')
  }
}
</script>

<style scoped></style>
