<template>
  <div>
    <v-dialog v-model="internalDialog" persistent width="1080px">
      <v-card>
        <v-overlay :value="loading" absolute color="white" :opacity="0.7">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-overlay>
        <div class="chip-container">
          <transition name="slide-fade">
            <v-chip v-if="showChip" class="custom-chip t-bw-primary" color="primary" small>
              <strong>{{ processedOrders }} / {{ totalOrders }}</strong>
            </v-chip>
          </transition>
        </div>
        <v-card-title>
          {{ $t("abrapa.titles.importOrders") }}
        </v-card-title>
        <v-card-text class="mt-6">
          <v-form ref="form" v-model="valid">
            <v-container>
              <order-template-component :data="xlsxPages" />
              <div class="d-flex justify-center align-center align-content-center mb-4">
                <v-divider class="mx-6" />
                <b>{{ $t('abrapa.titles.letterO') }}</b>
                <v-divider class="mx-6" />
              </div>
              <file-dropzone-component ref="fileDropzone" @file-uploaded="handleFileReceived" @file-cleared="clear()"></file-dropzone-component>
            </v-container>

            <orders-skeleton-table v-if="orders.length === 0" :orders="orders" :headers="headers" />
            <v-data-table v-else
                          :headers="headers"
                          :items="orders"
                          :items-per-page="itemsPerPage"
                          :page.sync="page"
                          :search="search"
                          :item-class="hasError"
                          item-key="id"
                          dense
                          outlined
                          :footer-props="{
                            ...rowsPerPageItemsOptions,
                            itemsPerPageText: $t('common.table.itemsPerPageText'),
                            pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}'
                          }"
                          :loading-text="$t('common.notification.loadData')"
                          :no-data-text="$t('common.ui.not_data_found')"
                          @update:items-per-page="itemsPerPage = $event"
                          @update:page="page = $event"
            >
              <template v-slot:top>
                <v-row class="my-4">
                  <v-col cols="12" :md="6" class="ma-0 pa-0">
                    <v-text-field append-icon="mdi-magnify" v-bind="textProps" v-model="search" label="Search"
                      class="mx-4" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" :md="6" class="ma-0 pa-0 d-flex justify-end align-center">
                    <v-chip small class="ma-2">
                      <v-icon color="primary" small left>mdi-form-textbox</v-icon>
                      {{ $t('common.legend.tapToEdit') }}
                    </v-chip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`header.originSegundaryLocation`]="{ header }">
                <replace-location-column-component
                  :items="orders"
                  :header="header.value"
                  :length="orders.length"
                  :text="header.text"
                  :selected="selected"
                />
              </template>
              <template v-slot:[`header.destinySegundaryLocation`]="{ header }">
                <replace-location-column-component
                  :items="orders"
                  :header="header.value"
                  :length="orders.length"
                  :text="header.text"
                  :selected="selected"
                />
              </template>
              <template v-slot:[`header.store`]="{ header }">
                <replace-store-column-component
                  :items="orders"
                  :header="header.value"
                  :length="orders.length"
                  :text="header.text"
                  :selected="selected"
                />
              </template>
              <template v-slot:[`header.g_product[0].unit`]="{ header }">
                <replace-unit-column-component
                  :items="orders"
                  :header="header.value"
                  :length="orders.length"
                  :text="header.text"
                  :selected="selected"
                />
              </template>
              <template v-slot:[`header.selected`]>
                <v-checkbox
                  color="primary"
                  :input-value="allSelected"
                  @change="toggleSelectAll"
                ></v-checkbox>
              </template>
              <template v-slot:item.selected="{ item }">
                <v-checkbox
                  color="primary"
                  v-model="item.selected"
                @change="updateSelectedItems"
              ></v-checkbox>
            </template>
            <template v-slot:item.number="{ index }">
              {{ (page - 1) * itemsPerPage + index + 1 }}
            </template>
            <template v-slot:item.id="{ item }">
              <div class="d-flex justify-start align-center align-items-center">
                <v-chip class="mx-0 px-1" :style="chipStyle" small>
                  <span class="black--text mx-2">{{ trimmedUUID(item.id) }}</span>
                  <v-icon class="mouse-pointer" @click="copyToClipboard(item)" small color="black">mdi-content-copy</v-icon>
                </v-chip>
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              <v-edit-dialog :return-value.sync="item.date" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.date }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps" v-model="item.date"
                    :label="$t('abrapa.fields.date')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.originSegundaryLocation="{ item }">
              <v-edit-dialog :return-value.sync="item.originSegundaryLocation" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.originSegundaryLocation }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps" v-model="item.originSegundaryLocation"
                    :label="$t('abrapa.fields.originSegundaryLocation')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.destinySegundaryLocation="{ item }">
              <v-edit-dialog :return-value.sync="item.destinySegundaryLocation" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.destinySegundaryLocation }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps"
                    v-model="item.destinySegundaryLocation" :label="$t('abrapa.fields.destinySegundaryLocation')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.store="{ item }">
              <v-edit-dialog :return-value.sync="item.store" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.store }}
                <template v-slot:input>
                  <v-autocomplete v-model="item.store" :items="stores" class="mt-4" item-value="name" item-text="name"
                    outlined dense color="primary" item-color="primary" :rules="required"
                    @change="updateStore(item)">
                    <template v-slot:label>
                      {{ $t("abrapa.fields.store") }}
                      <strong class="red--text">*</strong>
                    </template>
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          {{ item.name + " (" + item.url + ")" }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.name + " (" + item.url + ")" }}
                    </template>
                  </v-autocomplete>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].name="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].translatedName"
                @save="updateTranslations(item.g_product[0], 'name')" @cancel="cancelEdit(item.g_product[0], 'name')"
                large>
                {{ translate(item.g_product[0].name) }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps"
                    v-model="item.g_product[0].translatedName" :label="$t('abrapa.fields.product')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].description="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].translatedDescription"
                @save="updateTranslations(item.g_product[0], 'description')"
                @cancel="cancelEdit(item.g_product[0], 'description')" large>
                {{ translate(item.g_product[0].description) }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps"
                    v-model="item.g_product[0].translatedDescription" :label="$t('abrapa.fields.description')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].category="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].translatedCategory"
                @save="updateTranslations(item.g_product[0], 'category')"
                @cancel="cancelEdit(item.g_product[0], 'category')" large>
                {{ translate(item.g_product[0].category) }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps"
                    v-model="item.g_product[0].translatedCategory" :label="$t('abrapa.fields.category')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].gtin="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].gtin" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.g_product[0].gtin }}
                <template v-slot:input>
                  <v-text-field
                    v-on:keyup.86="replaceGtin"
                    v-on:keyup.ctrl.86="replaceGtin"
                    @keyup="validGtin14($event, item.g_product[0])"
                    @keydown="validGtin14Delete($event, item.g_product[0])"
                    :rules="requireAndGTIN14Rules(13)"
                    class="mt-4"
                    v-bind="textProps"
                    v-model="item.g_product[0].gtin"
                    :label="$t('abrapa.fields.gtin')">
                  </v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].cotton_percent="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].cotton_percent" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.g_product[0].cotton_percent + '%' }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps"
                    v-model="item.g_product[0].cotton_percent" :label="`% ${$t('abrapa.fields.cotton_percent')}`"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].lot="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].lot" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.g_product[0].lot }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps" v-model="item.g_product[0].lot"
                    :label="$t('abrapa.fields.lot')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].quantity="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].quantity" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.g_product[0].quantity }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps" v-model="item.g_product[0].quantity"
                    :label="$t('abrapa.fields.quantity')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.g_product[0].unit="{ item }">
              <v-edit-dialog :return-value.sync="item.g_product[0].unit" @save="saveEdit" large :save-text="$t('common.buttons.save')" :cancel-text="$t('common.buttons.cancel')">
                {{ item.g_product[0].unit }}
                <template v-slot:input>
                  <v-text-field :rules="required" class="mt-4" v-bind="textProps" v-model="item.g_product[0].unit"
                                :label="$t('abrapa.fields.unit')"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-form>
        <v-alert
          id="errors"
          v-if="failedOrders.length > 0"
          class="mt-2"
          border="left"
          color="red"
          dense
          text
          type="error"
          icon="mdi-close-octagon-outline"
        >
          <div class="d-flex justify-space-between align-content-center align-center">
            <p class="ma-0 pa-0">{{ $t("abrapa.alerts.importErrorsDetected") }}</p>
            <v-btn class="mx-2" color="error darken-1" text @click="exports()">
              <v-icon left small color="red">mdi-bug-outline</v-icon>
              {{ $t("common.buttons.export") }}
            </v-btn>
          </div>
        </v-alert>

      </v-card-text>
      <v-card-actions class="py-4 px-8">
        <!-- <v-btn color="primary darken-1" text @click="exportJson()" :disabled="!valid || !(orders.length > 0)">
          {{ $t("common.buttons.export") + ' JSON' }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="neuter" text small @click="close" :disabled="loading">
          {{ $t("common.buttons.close") }}
        </v-btn>
        <v-btn color="primary" class="t-bw-primary--text" elevation="0" small @click="imports" :disabled="!valid || !(orders.length > 0) || loading" :loading="loading">
          {{ $t("common.buttons.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Diálogo de éxito -->
  <v-dialog v-model="showSuccessDialog" max-width="400" persistent>
    <v-card class="success-dialog">
      <v-card-text class="text-center pt-8">
        <div class="d-flex justify-center">
          <h2 class="text-h5 d-flex text-center success--text">
            <v-icon 
              color="success" 
              class="mr-2"
            >
              mdi-check-circle-outline
            </v-icon>
            {{ $t('abrapa.titles.success') }}
          </h2>
        </div>
        <span class="subtitle-1">
          {{ $t('abrapa.notifications.importSuccess') }}
        </span>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="showSuccessDialog = false"
          elevation="0"
          small
        >
          {{ $t('common.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
  import * as XLSX from "xlsx";
  import moment from "moment/moment";
  import i18n from "@/plugins/i18n"
  import ExcelJS from 'exceljs';
  import { v4 as uuidv4 } from 'uuid';
  import { saveAs } from 'file-saver';
  import { mapActions, mapGetters } from "vuex";
  import { GtinMixin } from "@/mixins/gtin.mixin";
  import { IntegrationConfigMixin } from "@/module/integrations/abrapa/mixins/integration-config.mixin";
  import { IntegrationDialogMixin } from "@/module/integrations/abrapa/mixins/integration-dialog.mixin";
  import SimpleLocationFilters from "@/components/filters/SimpleLocationFilters.vue";
  import LanguageService from "@/services/LanguajeService";
  import OrdersSkeletonTable from './OrdersSkeletonTable.vue';
  import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue"
  import ReadMore from "@/components/ReadMoreComponent.vue"
  import ReplaceValuesComponent from "@/module/integrations/abrapa/components/ReplaceLocationColumnComponent.vue"
  import FileDropzoneComponent from "@/module/integrations/abrapa/components/FileDropzoneComponent.vue"
  import ReplaceLocationColumnComponent from "@/module/integrations/abrapa/components/ReplaceLocationColumnComponent.vue"
  import ReplaceStoreColumnComponent from "@/module/integrations/abrapa/components/ReplaceStoreColumnComponent.vue"
  import ReplaceUnitColumnComponent from "@/module/integrations/abrapa/components/ReplaceUnitColumnComponent.vue"
  import OrderTemplateComponent from "@/module/integrations/abrapa/components/OrderTemplateComponent.vue"

  export default {
    name: "IntegrationImportOrdersDialog",
    components: { OrderTemplateComponent, ReplaceUnitColumnComponent, ReplaceStoreColumnComponent, ReplaceLocationColumnComponent, FileDropzoneComponent, ReplaceValuesComponent, ReadMore, AFooterPaginatorField, SimpleLocationFilters, OrdersSkeletonTable },
    mixins: [IntegrationConfigMixin, IntegrationDialogMixin, GtinMixin],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        valid: false,
        validOrigin: false,
        validDestination: false,
        internalDialog: this.value,
        panel: null,
        date: null,
        location: null,
        originSegundaryLocation: null,
        storage: null,
        destinySegundaryLocation: null,
        stores: [],
        units: [],
        g_product: [],
        selectedOriginLocation: null,
        selectedDestinyLocation: null,
        selectedStore: null,
        orders: [],
        file: null,
        search: "",
        translatedFields: {},
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "primary",
          itemColor: "primary",
        },
        showAlert: false,
        uploadProgress: 0,
        loading: false,
        rowsPerPageItemsOptions: {
          itemsPerPageOptions: [5, 10, 20, -1],
        },
        schema: {
          date: true,
          originSegundaryLocation: true,
          destinySegundaryLocation: true,
          store: true,
          g_product: [
            {
              name: [{ language: true, label: true }],
              description: [{ language: true, label: true }],
              category: [{ language: true, label: true }],
              gtin: true,
              cotton_percent: true,
              lot: true,
              quantity: true,
              unit: true
            }
          ]
        },
        headers: [
          { text: '', value: 'selected', width: '10', sortable: false },
          { text: "#", value: "number", val: "number", width: '10' },
          { text: "ID", value: "id", val: "id", width: 'auto' },
          { text: i18n.t('abrapa.headers.date').toUpperCase(), value: "date", width: 'auto' },
          { text: i18n.t('abrapa.headers.origin').toUpperCase(), value: "originSegundaryLocation", width: '200' },
          { text: i18n.t('abrapa.headers.destination').toUpperCase(), value: "destinySegundaryLocation", width: '200' },
          { text: i18n.t('abrapa.headers.store').toUpperCase(), value: "store", width: 'auto' },
          { text: i18n.t('abrapa.headers.product').toUpperCase(), value: "g_product[0].name", width: '200' },
          { text: i18n.t('abrapa.headers.description').toUpperCase(), value: "g_product[0].description", width: 'auto' },
          { text: i18n.t('abrapa.headers.category').toUpperCase(), value: "g_product[0].category", width: 'auto' },
          { text: i18n.t('abrapa.headers.gtin').toUpperCase(), value: "g_product[0].gtin", width: 'auto' },
          { text: i18n.t('abrapa.headers.cotton_percent').toUpperCase(), value: "g_product[0].cotton_percent", width: 'auto' },
          { text: i18n.t('abrapa.headers.lot').toUpperCase(), value: "g_product[0].lot", width: 'auto' },
          { text: i18n.t('abrapa.headers.quantity').toUpperCase(), value: "g_product[0].quantity", width: 'auto' },
          { text: i18n.t('abrapa.headers.unit').toUpperCase(), value: "g_product[0].unit", width: 'auto' },
        ],
        failedOrders: [],
        selected: [],
        page: 1,
        itemsPerPage: 5,
        showChip: false,
        processedOrders: 0,
        totalOrders: 0,
        xlsxPages: {},
        showSuccessDialog: false,
      };
    },
    async created() {
      this.stores = await this.fetchStores();
      this.units = await this.fetchUnits();
      await this.fetchSStorageDevices([
        { stringFilter: "", isActive: true },
        this.$toast
      ]);

      this.xlsxPages = {
        units: _.map(this.units, (u) => ({ name: u.name, value: u.code })),
        stores: _.map(this.stores, (s) => ({ name: s.name, value: s.api_key })),
        locations: _.map(this.sStorageDevices, (l) => ({ name: l.control_label, value: l.gln })),
      }
    },
    watch: {
      page(newVal) {
        console.log("Página actualizada a:", newVal);
      },
      itemsPerPage(newVal) {
        console.log("Ítems por página actualizado a:", newVal);
      },
      value(val) {
        this.internalDialog = val;
      },
      internalDialog(val) {
        this.$emit("input", val);
        if (val) {
          this.syncTranslations();
        }
      }
    },
    computed: {
      ...mapGetters({
        sStorageDevices: "storage/sStorageDevices",
        required: 'general/requireRules',
        requireAndGTIN14Rules: 'general/requireAndGTIN14Rules',
      }),
      chipStyle() {
        return {
          background: '#f5f5f5',
          color: '#b0b0b0',
          borderRadius: '10px',
          fontWeight: 'bold'
        };
      },
      allSelected() {
        return this.orders.length && this.orders.every(order => order.selected);
      },
      itemsToUse() {
        return this.orders.filter(order => order.selected);
      },
      isValid() {
        return this.valid && this.orders.length > 0;
      },
      computedDate: {
        get() {
          return this.date ? new Date(this.date) : null;
        },
        set(data) {
          this.date = data ? new Date(data) : null;
        },
      },
    },
    methods: {
      ...mapActions({
        createOrder: "abrapa/CREATE_ORDER",
        fetchStores: "abrapa/FETCH_STORES",
        fetchUnits: "abrapa/FETCH_UNITS",
        fetchSStorageDevices: "storage/fetchSStorageDevices"
      }),
      trimmedUUID(uuid) {
        return `${uuid.substr(0, 8)}...`;
      },
      copyToClipboard(item) {
        const originalId = item.id;
        navigator.clipboard.writeText(originalId).then(() => {
          item.id = i18n.t("tokens.text.copied");
          setTimeout(() => {
            item.id = originalId;
          }, 2000);
        }).catch(err => {
          console.error('Error copying to clipboard: ', err);
        });
      },
      updateSelectedItems() {
        this.selected = this.orders.filter(order => order.selected);
      },
      toggleSelectAll() {
        const value = !this.allSelected;
        this.orders.forEach(order => {
          order.selected = value;
        });
        this.selected = value ? [...this.orders] : [];
      },
      handleFileReceived(file) {
        this.file = file;
        this.orders = [];
        this.selected = [];
        this.handleFileUpload(file)
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      getStoreDisplayName(store) {
        if (!store) return '';

        if (typeof store === 'string') {
          const foundStore = this.stores.find(s => s.api_key === store);
          return foundStore ? `${foundStore.name}` : '';
        } else if (store && store.api_key) {
          const foundStore = this.stores.find(s => s.api_key === store.api_key);
          return foundStore ? `${foundStore.name}` : '';
        }
        return '';
      },
      updateStore(item) {
        const store = this.stores.find(store => store.name === item.store);
        item.store = store ? store.name : item.store;
      },
      updateTranslations(product, key) {
        const newValue = product[`translated${this.capitalize(key)}`];
        product[key] = product[key].map(translation => ({
          ...translation,
          label: newValue
        }));
      },
      cancelEdit(product, key) {
        product[`translated${this.capitalize(key)}`] = this.translatedFields[key];
      },
      capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
      },
      syncTranslations() {
        this.orders.forEach(order => {
          order.g_product.forEach(product => {
            product.translatedName = this.translate(product.name);
            product.translatedDescription = this.translate(product.description);
            product.translatedCategory = this.translate(product.category);
            // Guardar los valores originales
            this.translatedFields.name = product.translatedName;
            this.translatedFields.description = product.translatedDescription;
            this.translatedFields.category = product.translatedCategory;
          });
        });
      },
      deleteProduct(index) {
        this.g_product.splice(index, 1);
        this.panel = null;
      },
      clear() {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.orders = [];
        this.search = "";
        this.uploadProgress = 0;
        this.failedOrders = [];
        this.loading = false;
        this.$nextTick(() => {
          this.file = null;
        });
      },
      close() {
        this.clear()
        this.$refs.fileDropzone.clearFile();
        this.internalDialog = false;
      },
      async saveEdit() {
        this.loading = true;
        // Implement save logic here if needed
        this.loading = false;
      },
      handleFileUpload(file) {
        if (file) {
          this.file = file;
          setTimeout(() => {
            const reader = new FileReader();
            reader.onload = (e) => {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

              if (this.validateHeaders(jsonData, worksheet)) {
                this.processExcelData(jsonData);
              } else {
                alert(i18n.t('abrapa.alerts.wrongHeadersFile'));
              }
            };
            reader.readAsArrayBuffer(file);
          }, 0);
        }
      },
      validateHeaders(data, worksheet) {
        const headers = data[0];
        const expectedKeys = [
          'date',
          'origin',
          'destination',
          'product',
          'store',
          'gtin',
          'category',
          'description',
          'cotton_percent',
          'lot',
          'quantity',
          'unit'
        ];

        for (let i = 0; i < headers.length; i++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: i });
          const cell = worksheet[cellAddress];

          if (!cell || !cell.c) {
            return false;
          }

          const note = cell.c[0].t;
          const key = note.replace(/<[^>]*>?/gm, '');

          if (!key || expectedKeys[i] !== key) {
            return false;
          }
        }
        return true;
      },
      parseDate(dateString) {
        const formats = [
          'DD/MM/YYYY',
          'DD-MM-YYYY',
          'DD/MM/YYYY HH:mm:ss',
          'DD-MM-YYYY HH:mm:ss',
          'YYYY-MM-DDTHH:mm:ssZ',  // ISO 8601 format
          'DD/MM/YY HH:mm',        // Short year format with time
          'DD/MM/YYYY HH:mm'       // Full year format with time
        ];

        let date = null;

        // Check if dateString is a number (Excel format)
        if (!isNaN(dateString)) {
          date = moment.utc(new Date((dateString - (25567 + 2)) * 86400 * 1000));
        } else {
          for (let format of formats) {
            date = moment.utc(dateString, format, true);
            if (date.isValid()) {
              break;
            }
          }
        }

        if (!date.isValid()) {
          throw new Error('Invalid date format');
        }

        // If dateString is a string and does not include time, set default time to 12:00:00
        if ((typeof dateString === 'string' && !dateString.includes(':')) || !isNaN(dateString)) {
          date.hour(12).minute(0).second(0).millisecond(0);
        }

        return date;
      },
      processExcelData(data) {
        const cleanAndValidateGTIN = (gtin) => {
          let text = gtin.toString().replace(/[^0-9]/g, "");
          text = text.padStart(13, "0");
          if (text.length === 13) {
            let sum = 0;
            for (let i = 0; i < text.length; i++) {
              let digit = parseInt(text[i], 10);
              sum += digit * (i % 2 === 0 ? 1 : 3);
            }
            let checkDigit = (10 - (sum % 10)) % 10;
            return text + checkDigit;
          }
          return text;
        };

        console.log(data);

        for (let i = 1; i < data.length; i++) {
          const row = data[i];

          if (row && row.length > 0) {
            const dateString = row[0];
            const date = this.parseDate(dateString).format('YYYY-MM-DDTHH:mm:ss');

            const order = {
              id: uuidv4(), // Genera el UUID único para la orden
              date: date,
              originSegundaryLocation: row[1],
              destinySegundaryLocation: row[2],
              store: row[4],
              g_product: [
                {
                  name: LanguageService.setKey3(
                    row[3],
                    row[3],
                    this.languageStructure
                  ),
                  description: LanguageService.setKey3(
                    row[7],
                    row[7],
                    this.languageStructure
                  ),
                  category: LanguageService.setKey3(
                    row[6],
                    row[6],
                    this.languageStructure
                  ),
                  gtin: cleanAndValidateGTIN(row[5]),
                  cotton_percent: row[8],
                  lot: row[9].toString(),
                  quantity: row[10].toString(),
                  unit: row[11],
                },
              ],
              status: 'success',
              error: null,
              selected: false,
              number: i
            };
            this.orders.push(order);
          }
        }
        this.syncTranslations();
      },
      prepareItemsForImport(items) {
        const preparedItems = [];
        items.forEach((o) => {
          let hasErrors = false;
          o.errors = []; // Inicializar un array para acumular los errores del registro

          // Validar el store
          const store = this.stores.find(store => store.name === o.store);
          if (!store) {
            hasErrors = true;
            o.errors.push(this.$t('abrapa.notifications.storeNotFound', { store: o.store }));
          } else {
            o.correctedStore = store.api_key;
          }

          // Validar los productos
          const g_product = o.g_product.map(p => {
            const unit = this.units.find(unit => unit.name === p.unit);
            if (!unit) {
              hasErrors = true;
              o.errors.push(this.$t('abrapa.notifications.unitNotFound', { unit: p.unit }));
              return null;
            }
            return {
              ...p,
              unit: unit.code,
            };
          });

          // Si se detectaron errores, el registro se marca como error
          if (hasErrors) {
            o.status = 'error';
            o.error = o.errors.join(', '); // Concatenar todos los errores en un solo campo
            this.failedOrders.push(o);
          } else if (!g_product.includes(null)) {
            o.correctedGProduct = g_product;
            preparedItems.push(o);
          }
        });

        return preparedItems;
      },

      async processImport(items) {
        this.loading = true;
        this.uploadProgress = 0;
        this.totalOrders = items.length;
        this.processedOrders = 0;
        this.showChip = true;

        for (let i = 0; i < items.length; i++) {
          try {
            const item = {
              ...items[i],
              store: items[i].correctedStore,
              g_product: items[i].correctedGProduct
            };

            const cleared = this.cleanObjectBySchema(item, this.schema);

            await this.createOrder([cleared, true]);

            items[i].status = 'success';
            items[i].error = null;
          } catch (error) {
            const errorResponse = error.response ? error.response.data : {};
            const coreMessage = errorResponse.coreMessage || 'Unknown error';

            items[i].status = 'error';
            items[i].error = coreMessage;
            items[i].errors = (items[i].errors || []).concat(coreMessage);

            this.failedOrders.push(items[i]);
          } finally {
            this.processedOrders++;
            this.uploadProgress = (this.processedOrders / this.totalOrders) * 100;
          }
        }

        this.loading = false;
        setTimeout(() => {
          this.showChip = false;
        }, 2000);

        if (this.failedOrders.length === 0) {
          this.showSuccessDialog = true;
        }
      },

      cleanObjectBySchema(obj, schema) {
        if (Array.isArray(schema)) {
          if (Array.isArray(obj)) {
            return obj.map(item => this.cleanObjectBySchema(item, schema[0]));
          }
          return obj;
        } else if (typeof schema === 'object' && typeof obj === 'object' && obj !== null) {
          const cleanedObject = {};

          for (const key in schema) {
            if (schema.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
              cleanedObject[key] = this.cleanObjectBySchema(obj[key], schema[key]);
            }
          }

          return cleanedObject;
        }

        return obj;
      },

      async imports() {
        this.failedOrders = [];

        let itemsToImport = this.itemsToUse.filter(order => order.selected);

        if (!(itemsToImport.length > 0)) {
          itemsToImport = [...this.orders];
        }

        itemsToImport = this.prepareItemsForImport(itemsToImport);
        await this.processImport(itemsToImport);

        if (this.failedOrders.length > 0) {
          this.orders = this.orders.map(order => {
            if (this.failedOrders.includes(order)) {
              order.status = 'error';
            }
            return order;
          });
        }

        this.orders = this.orders.filter(order => order.status !== 'success');
      },

      exports() {
        if (this.failedOrders.length > 0) {
          try {
            const workbook = new ExcelJS.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            const worksheet = workbook.addWorksheet('Failed Orders');

            worksheet.columns = [
              { header: '#', key: 'number', width: 10 },
              { header: 'ID', key: 'id', width: 40 },
              { header: i18n.t('abrapa.headers.errors'), key: 'error', width: 50 }
            ];

            const headerRow = worksheet.getRow(1);
            headerRow.eachCell(cell => {
              cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, name: 'Arial' };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF2A6099' }
              };
              cell.alignment = { horizontal: 'center', vertical: 'middle' };
            });

            this.failedOrders.forEach(order => {
              const row = worksheet.addRow({
                number: order.number,
                id: order.id,
                error: order.errors.join('\n')
              });

              row.getCell('error').alignment = { wrapText: true };
            });

            worksheet.columns.forEach(column => {
              let maxLength = 0;
              column.eachCell({ includeEmpty: true }, cell => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                  maxLength = columnLength;
                }
              });
              column.width = maxLength < 10 ? 10 : maxLength + 2;
            });

            workbook.xlsx.writeBuffer().then(buffer => {
              const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              saveAs(blob, 'FailedOrdersFormatted.xlsx');
            });
          } catch (error) {
            console.error('Error exporting the file:', error);
          }
        }
      },

      hasError(item) {
        const checkValues = (obj) => {
          return Object.entries(obj).some(([key, value]) => {
            if (['error', 'selected'].includes(key)) return false;
            if (typeof value === 'object' && value !== null) {
              return checkValues(value);
            }
            return value === undefined || value === null || value === '';
          });
        };

        return checkValues(item) || item.status === 'error' ? 'row-error' : '';
      },
      exportJson() {
        const jsonString = JSON.stringify(this.itemsToUse, null, 2);
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.json");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      replaceGtin(event){
        this.editedItem.gtin =  event.currentTarget.value.toString().replaceAll('"','')
      },
    },
  };
</script>

<style scoped lang="scss">
  .title__tooltip {
    background-color: rgba(0, 0, 0) !important;
    color: white;
    font-size: 12px;
    padding: 3px 5px;
  }

  .chip-container {
    position: relative;
    display: flex;
  }

  .copy-chip {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .chip-container:hover .copy-chip {
    opacity: 1;
  }

  .v-chip {
    border-radius: 0.3em !important;
  }

  .expanding-button__delete {
    position: relative;
    overflow: hidden;
    transition: width 0.3s ease;
    justify-content: flex-start;
  }

  .expanding-button__delete {
    width: 20px;
    padding-left: 18px !important;
  }

  .expanding-button__delete:hover {
    width: 105px;
  }

  .button-text {
    transition: transform, opacity 0.3s ease;
    opacity: 0;
    transform: translateX(-100%);
    display: inline-block;
  }

  .expanding-button__delete:hover .button-text {
    opacity: 1;
    transform: translateX(0%);
  }

  .bordered {
    border: 2px solid rgba(3, 172, 140, 0.3) !important;
    box-shadow: 0 4px 8px rgba(148, 148, 148, 0.3) !important;
  }

  .non-bordered {
    border: 1px solid rgba(0, 0, 0, 0.37) !important;
  }

  >>> .v-data-table .v-data-table-header th {
    white-space: nowrap;
    width: min-content;
  }

  >>> th span {
    font-size: 12px;
  }

  .chip-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  .custom-chip {
    padding: 0 10px;
    border-radius: 16px;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }

  :deep(#errors.v-alert) {
    .v-alert__wrapper .v-alert__icon {
      margin-top: .4rem;
    }
  }

  .success-dialog {
    border-radius: 8px;
    
    .v-card__text {
      padding-top: 36px;
    }
    
    .v-icon {
      display: block;
      margin: 0 auto;
    }
  }
</style>
