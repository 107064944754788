<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="integrations.label"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-row justify="start" class="my-4 mx-1">
      <span v-for="(card, index) in cards" :key="index">
        <CardWrapper class="mx-4" :card="card">
          <template v-slot:content>
            <v-icon class="pa-6" x-large :color="card.color">{{ card.icon }}</v-icon>
          </template>
          <template v-slot:actions>
            <v-btn :disabled="!check([{ domain: card.authority.domain, permisions: [card.authority.permission] }])" block elevation="0" color="primary" @click="openDialog(card.action)"> {{ $t(card.button) }} </v-btn>
          </template>
        </CardWrapper>
      </span>
    </v-row>
    <!-- Product Configuration Dialog Component -->
    <IntegrationProductDialog v-model="dialog.product" />
    <IntegrationOrderDialog v-model="dialog.order" />
    <IntegrationImportOrdersDialog v-model="dialog.importOrders" />
  </v-container>
</template>

<script>
import CardWrapper from "@integrations/common/CardWrapper.vue";
import i18n from "@/plugins/i18n";
import IntegrationProductDialog from "@integrations/abrapa/components/ProductDialog.vue";
import IntegrationOrderDialog from "@/module/integrations/abrapa/components/OrderDialog.vue";
import IntegrationImportOrdersDialog from "@/module/integrations/abrapa/components/ImportOrdersDialog.vue";
import PermissionService from "@/services/PermisionService";

export default {
  name: "IndexView",
  components: { IntegrationOrderDialog, IntegrationProductDialog, IntegrationImportOrdersDialog, CardWrapper },
  data() {
    return {
      dialog: {
        product: false,
        order: false,
        importOrders: false
      },
      cards: [{
        title: i18n.t("abrapa.titles.productRegistration"),
        subtitle: i18n.t("abrapa.subtitles.productRegistration"),
        icon: 'mdi-shopping-outline',
        color: 'secondary',
        action: 'product',
        button: 'common.buttons.register',
        authority: {
          domain: 'AbrapaProductAPI',
          permission: 'Write'
        }
      }, {
        title: i18n.t("abrapa.titles.orderRegistration"),
        subtitle: i18n.t("abrapa.subtitles.orderRegistration"),
        icon: 'mdi-file-document-edit-outline',
        color: 'secondary',
        action: 'order',
        button: 'common.buttons.register',
        authority: {
          domain: 'AbrapaOrderAPI',
          permission: 'Write'
        }
      }, {
        title: i18n.t("abrapa.titles.importOrders"),
        subtitle: i18n.t("abrapa.subtitles.importOrders"),
        icon: 'mdi-import',
        color: 'secondary',
        action: 'importOrders',
        button: 'common.buttons.import',
        authority: {
          domain: 'AbrapaOrderAPI',
          permission: 'Import'
        }
      }]
    }
  },
  methods: {
    openDialog(action) {
      this.dialog[action] = true;
    },
    check(permissions) {
      return PermissionService.check(permissions);
    },
  }
}
</script>

<style scoped>
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;

  .v-card__text {
    padding-bottom: 0;
  }
}

.card-integration {
  border-radius: 4px;
  transition: box-shadow .3s ease-in-out;
  color: #424242;
}

.card-integration:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* slightly more prominent on hover */
}

.custom__chip {
  border-radius: .4em !important;
  margin: 0 10px;
}
</style>