<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text v-bind="attrs" v-on="on">
        <v-icon small left>mdi-cog</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <v-card width="350">
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <slot name="fields"></slot>
            <v-col cols="6" v-if="!(selected && selected.length > 0)">
              <v-text-field v-model="startRow" type="number" min="1" v-bind="textProps" :rules="required">
                <template v-slot:label>
                  {{ $t("abrapa.fields.startRow") }}
                  <strong class="red--text">*</strong>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" v-if="!(selected && selected.length > 0)">
              <v-text-field v-model="endRow" type="number" :max="length" v-bind="textProps" :rules="required">
                <template v-slot:label>
                  {{ $t("abrapa.fields.endRow") }}
                  <strong class="red--text">*</strong>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="closeMenu">{{ $t('common.buttons.close') }}</v-btn>
        <v-btn color="primary" text @click="applySettings" :disabled="!valid">{{ $t('common.buttons.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: ['header', 'items', 'length', 'text', 'selected', 'selectedField'],
  data() {
    return {
      menu: false,
      startRow: '',
      endRow: '',
      loading: false,
      textProps: {
        persistentHint: true,
        small: true,
        flat: true,
        outlined: true,
        dense: true,
        clearable: true,
        color: "primary",
        itemColor: "primary",
      },
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      required: "general/requireRules",
      onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat'
    }),
  },
  methods: {
    applySettings() {
      if (this.selected && this.selected.length > 0) {
        if (!this.selectedField) {
          alert('Todos los campos son necesarios.');
          return;
        }

        this.selected.forEach((order) => {
          this.setNestedProperty(order, this.header, this.selectedField);
        });
      } else {
        if (!this.selectedField || !this.startRow || !this.endRow) {
          alert('Todos los campos son necesarios.');
          return;
        }

        this.items.forEach((order, index) => {
          if (index >= this.startRow - 1 && index <= this.endRow - 1) {
            this.setNestedProperty(order, this.header, this.selectedField);
          }
        });
      }

      this.closeMenu();
    },
    setNestedProperty(obj, path, value) {
      const keys = path.split('.');
      let current = obj;

      for (let i = 0; i < keys.length - 1; i++) {
        // Check if the current key is an array index
        if (keys[i].endsWith(']')) {
          const arrayIndex = parseInt(keys[i].slice(keys[i].indexOf('[') + 1, keys[i].indexOf(']')));
          const arrayKey = keys[i].slice(0, keys[i].indexOf('['));
          if (!current[arrayKey]) {
            current[arrayKey] = [];
          }
          current = current[arrayKey][arrayIndex];
        } else {
          if (!current[keys[i]]) {
            current[keys[i]] = {};
          }
          current = current[keys[i]];
        }
      }

      // Handle the last key
      const lastKey = keys[keys.length - 1];
      if (lastKey.endsWith(']')) {
        const arrayIndex = parseInt(lastKey.slice(lastKey.indexOf('[') + 1, lastKey.indexOf(']')));
        const arrayKey = lastKey.slice(0, lastKey.indexOf('['));
        if (!current[arrayKey]) {
          current[arrayKey] = [];
        }
        current[arrayKey][arrayIndex] = value;
      } else {
        current[lastKey] = value;
      }
    },
    closeMenu() {
      this.menu = false;
      this.$emit('update:selectedField', null);
      this.startRow = '';
      this.endRow = '';
    }
  }
};
</script>

<style scoped></style>
