<template>
  <div class="data-table-container">
    <v-data-table
      v-if="orders.length === 0"
      :headers="headers"
      :items-per-page="5"
      dense
      outlined
    >
      <template v-slot:body>
        <tbody>
          <tr v-for="n in 5" :key="n" class="skeleton-row">
            <td v-for="header in headers" :key="header.text">
              <v-skeleton-loader
                :boilerplate="true"
                type="text"
                class="skeleton-item"
                :style="{ opacity: 1 - (n - 1) * 0.1 }"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-overlay absolute :value="orders.length === 0" class="table-overlay" color="white" opacity="0.2">
      <!-- <v-chip small class="ma-2">
         <v-icon small left>mdi-alert-circle-outline</v-icon>
         {{ $t('common.ui.not_data_found') }}
       </v-chip> -->
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "OrdersSkeletonTable",
  props: {
    orders: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.data-table-container {
  position: relative;
}

.skeleton-row {
  opacity: 0.8;
}

.skeleton-item {
  margin: 0;
  padding: 0;
}

.table-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
