<template>
  <v-layout>
    <v-alert
      class="mt-2"
      border="left"
      color="orange"
      dense
      text
      type="warning"
      icon="mdi-alert-outline"
    >
      <div class="d-flex justify-space-between align-content-center align-center">
        <p class="ma-0 pa-0">{{ $t("common.titles.warningUploadTemplate") }}</p>
        <v-btn class="mx-2" text @click="downloadTemplate" color="#d68000">
          <v-icon left small color="#d68000">mdi-file-document-outline</v-icon>
          {{ $t("common.titles.nomenclators.downloadTemplate") }}
        </v-btn>
      </div>
    </v-alert>
  </v-layout>
</template>

<script>
  import ExcelJS from 'exceljs';
  import { saveAs } from 'file-saver';
  import i18n from "@/plugins/i18n";

  export default {
    name: 'OrderTemplateComponent',
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods: {
      async downloadTemplate() {
        try {
          // Crear un nuevo libro de trabajo
          const workbook = new ExcelJS.Workbook();
          workbook.created = new Date();
          workbook.modified = new Date();

          // Array de headers traducidos para la hoja principal
          const mainSheetHeaders = [
            { header: i18n.t('abrapa.headers.date'), key: 'date', width: i18n.t('abrapa.headers.date').length + 10 },
            { header: i18n.t('abrapa.headers.origin'), key: 'origin', width: i18n.t('abrapa.headers.origin').length + 10 },
            { header: i18n.t('abrapa.headers.destination'), key: 'destination', width: i18n.t('abrapa.headers.destination').length + 10 },
            { header: i18n.t('abrapa.headers.product'), key: 'product', width: i18n.t('abrapa.headers.product').length + 10 },
            { header: i18n.t('abrapa.headers.store'), key: 'store', width: i18n.t('abrapa.headers.store').length + 10 },
            { header: i18n.t('abrapa.headers.gtin'), key: 'gtin', width: i18n.t('abrapa.headers.gtin').length + 10 },
            { header: i18n.t('abrapa.headers.category'), key: 'category', width: i18n.t('abrapa.headers.category').length + 10 },
            { header: i18n.t('abrapa.headers.description'), key: 'description', width: i18n.t('abrapa.headers.description').length + 10 },
            { header: i18n.t('abrapa.headers.cotton_percent'), key: 'cotton_percent', width: i18n.t('abrapa.headers.cotton_percent').length + 10 },
            { header: i18n.t('abrapa.headers.lot'), key: 'lot', width: i18n.t('abrapa.headers.lot').length + 10 },
            { header: i18n.t('abrapa.headers.quantity'), key: 'quantity', width: i18n.t('abrapa.headers.quantity').length + 10 },
            { header: i18n.t('abrapa.headers.unit'), key: 'unit', width: i18n.t('abrapa.headers.unit').length + 10 }
          ];

          // Array de headers traducidos para la hoja de "locations"
          const locationsSheetHeaders = [
            { header: i18n.t('abrapa.headers.name'), key: 'name', width: i18n.t('abrapa.headers.name').length + 10 },
            { header: i18n.t('abrapa.headers.value'), key: 'value', width: i18n.t('abrapa.headers.value').length + 10 }
          ];

          // Array de headers traducidos para las hojas "units" y "stores"
          const unitAndStoreHeaders = [
            { header: i18n.t('abrapa.headers.name'), key: 'name', width: i18n.t('abrapa.headers.name').length + 10 }
          ];

          // Función para ajustar el ancho de las columnas automáticamente
          const autoAdjustColumns = (worksheet) => {
            worksheet.columns.forEach(column => {
              let maxLength = 0;
              column.eachCell({ includeEmpty: true }, cell => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                  maxLength = columnLength;
                }
              });
              column.width = (maxLength + 2) < 10 ? 10 : (maxLength + 4);
            });
          };

          // Crear la hoja principal con los headers traducidos
          const mainWorksheet = workbook.addWorksheet('Template');
          mainWorksheet.columns = mainSheetHeaders;

          // Agregar la fila de headers con las notas para los keys
          const headerRow = mainWorksheet.getRow(1);
          headerRow.values = mainSheetHeaders.map(header => header.header);
          headerRow.eachCell((cell, colNumber) => {
            const key = mainSheetHeaders[colNumber - 1].key; // -1 porque colNumber es 1-based
            cell.note = { texts: [{ font: { size: 9 }, text: key }] }; // Nota con el key
          });

          // Ajustar el ancho de las columnas automáticamente en la hoja principal
          autoAdjustColumns(mainWorksheet);

          // Aplicar estilos a los encabezados de la hoja principal
          headerRow.eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, name: 'Arial' };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF2A6099' }
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          });

          // Aplicar autofiltro en la hoja principal
          mainWorksheet.autoFilter = {
            from: 'A1',
            to: {
              row: 1,
              column: mainWorksheet.columns.length
            }
          };

          // Función para agregar hojas con datos
          const addSheet = (sheetName, data, headers) => {
            const worksheet = workbook.addWorksheet(sheetName);

            // Añadir encabezados
            worksheet.columns = headers;

            // Añadir la fila de headers con las notas para los keys
            const headerRow = worksheet.getRow(1);
            headerRow.values = headers.map(header => header.header);
            headerRow.eachCell((cell, colNumber) => {
              const key = headers[colNumber - 1].key; // -1 porque colNumber es 1-based
              cell.note = { texts: [{ font: { size: 9 }, text: key }] }; // Nota con el key
            });

            // Añadir filas de datos
            data.forEach(item => {
              worksheet.addRow(item);
            });

            // Aplicar estilos a los encabezados
            worksheet.getRow(1).eachCell(cell => {
              cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, name: 'Arial' };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF2A6099' }
              };
              cell.alignment = { horizontal: 'center', vertical: 'middle' };
            });

            // Ajustar el ancho de las columnas automáticamente
            autoAdjustColumns(worksheet);

            // Aplicar autofiltro
            worksheet.autoFilter = {
              from: 'A1',
              to: {
                row: 1,
                column: worksheet.columns.length
              }
            };
          };

          // Nombres de las hojas traducidos
          const sheetNames = {
            locations: i18n.t('abrapa.headers.locations'),
            stores: i18n.t('abrapa.headers.stores'),
            units: i18n.t('abrapa.headers.units')
          };

          // Agregar las demás hojas de datos con sus headers específicos
          addSheet(sheetNames.locations, this.data.locations, locationsSheetHeaders);
          addSheet(sheetNames.stores, this.data.stores, unitAndStoreHeaders);
          addSheet(sheetNames.units, this.data.units, unitAndStoreHeaders);

          // Guardar el libro como un archivo XLSX
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'Updated_ORDERS_TEMPLATE.xlsx');
        } catch (error) {
          console.error('Error creating the template:', error);
        }
      }
    }
  };
</script>
